<template>
	<div class="node-overview overview-closed" id="node-overview">
		<div class="container">
			<div class="controls">
				<a class="close" href="#" title="Close" @click.prevent="closeNodeOverview()">
					<i class="fas fa-times"></i>
				</a>
				<ul>
					<li v-if="(can(getAuthData, 'update_own_client_contentTree') && getUserClientRelationship(getAuthData, getClientSlug)) || (can(getAuthData, 'update_other_client_contentTree') && !getUserClientRelationship(getAuthData, getClientSlug))">
						<a class="edit" href="#" title="Edit" @click.prevent="openAddNode()">Edit node <i class="fa-duotone fa-pen-to-square theme-main"></i></a>
					</li>
					<li v-if="(can(getAuthData, 'update_own_client_contentTree') && getUserClientRelationship(getAuthData, getClientSlug)) || (can(getAuthData, 'update_other_client_contentTree') && !getUserClientRelationship(getAuthData, getClientSlug))">
						<a class="add" href="#" title="add Child" @click.prevent="addChildNode()">Add new <i class="fa-duotone fa-circle-plus theme-secondary"></i></a>
					</li>
				</ul>
			</div>
			<!-- end controls -->
			<div class="contents">
				<div class="row" v-for="(fieldGroup, key) in filteredFieldArray()" v-bind:key="key">
					<template v-for="(field, key) in fieldGroup.fields" v-bind:key="key">
						<div class="item grid-container" v-if="key !== 'hideFromUser' && field?.value">
							<div class="col heading">
								<p>{{ field.label }}</p>
							</div>
							<!-- end col -->
							<div class="col field">
								<template v-if="key == 'assign'">
									<p>{{ field.value.fullName }}</p>
								</template>
								<template v-if="typeof field.value === 'string'">
									<p v-if="field.label === 'Deadline' || field.label === 'Created'">{{ new Date(field.value).toLocaleString("en-GB") }}</p>
									<p v-else>{{ field.value }}</p>
								</template>
							</div>
						</div>
						<!-- end item -->
					</template>
				</div>
				<!-- end row -->
			</div>
			<!-- end contents -->
		</div>
		<!-- end container -->
	</div>
	<!-- end node-overview -->
</template>

<script>
import {mapGetters} from "vuex"
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

let main = null
let nodeOverview = null
let nodeForm = null

export default {
	data() {
		return {
			sortedRows: {},
		}
	},

	components: {
		// FontAwesomeIcon
	},
	computed: {
		...mapGetters("client", ["getCurrentNode", "getClientSlug"]),
		...mapGetters("auth", ["getAuthData"]),
	},
	methods: {
		filteredFieldArray() {
			// Turn fieldGroups into iterable aray
			let fieldGroupsArray = Object.values(this.getCurrentNode.fieldGroups)
			// Filter out fields that are hidden
			let nonHiddenFields = fieldGroupsArray.filter((item) => !item.fields.hideFromUser.value === true)
			// Filter out fieldGroups that have all empty fields
			let nonEmptyFields = nonHiddenFields.filter((fieldGroup) => {
				let fieldsArray = Object.values(fieldGroup.fields)
				for (let field of fieldsArray) {
					if (field.label !== "Hide from user") {
						if (field?.value) {
							return fieldGroup
						}
					}
				}
			})
			return nonEmptyFields
		},
		closeNodeOverview() {
			if (nodeOverview.classList.contains("overview-open")) {
				main.classList.remove("overview-open")
				main.classList.add("overview-closed")
				nodeOverview.classList.remove("overview-open")
				nodeOverview.classList.add("overview-closed")
			}
			this.$store.dispatch("client/clearSelectedNode")
			this.$emit("nodeOverviewClosed")
		},
		openAddNode() {
			if (nodeForm.classList.contains("form-closed")) {
				main.classList.remove("node-form-closed")
				main.classList.add("node-form-open")
				nodeForm.classList.remove("form-closed")
				nodeForm.classList.add("form-open")
			}
			// this.closeNodeOverview()
		},
		addChildNode() {
			let currentNode = this.$store.getters["client/getCurrentNode"]
			let author = this.getAuthData.fullName
			this.openAddNode()
			this.$store.dispatch("client/addNewNode", {parentNodeId: currentNode.id, author})
		},
	},
	mounted() {
		main = document.getElementById("main")
		nodeForm = document.getElementById("add-node-form")
		nodeOverview = document.getElementById("node-overview")
	},
}
</script>
<style lang="scss" scoped>
@import "./../../assets/scss/components/nodeOverview.scss";
</style>
